import { useState } from 'react';
import shirt from './shirt.jpg';
import lamp from './main_lamp.png';
import cardboard from './sculpt.png';
import queery from './queeryDesktop.png';
import final from './final.png';
import pim from './pim_paper.png';
import microsoft from './microsoft.png';
import spotify from './spotify.png';
import thred from './thred_logo.png';
import './App.css';
import { Stack, Typography, useMediaQuery } from '@mui/material';

const allItems = {
  fab: [
    {
      src: lamp,
      caption:
        '3d printed lamp from scanned face and animal models from Thingiverse',
      category: 'dig-fab',
    },
    {
      src: shirt,
      caption:
        'Parametrically generated shirt pattern, laser cut canvas, adorned with 3d printed buttons + appliqué',
      category: 'dig-fab',
    },
    {
      src: cardboard,
      caption:
        'Parametrically created wave form used to laser cut cardboard panels',
      category: 'dig-fab',
    },
  ],
  writing: [
    {
      src: pim,
      caption:
        "Research on technology opportunities to support unpaid caregivers' mental health",
      category: 'research',
    },
  ],
  code: [
    {
      src: final,
      caption:
        'Project presentation website for parametrically generated shirt',
      category: 'software',
    },
    {
      src: queery,
      caption: 'Queer community review site proof of concept',
      category: 'software',
    },
  ],
};
const Application = () => {
  const [clickedItem] = useState<string>();
  const mobile = useMediaQuery('(max-width:760px)');

  return (
    <div className="main">
      <div className="content">
        <Stack gap="50px">
          <Typography fontFamily={'"Press Start 2P"'} variant="h2">
            <Typography fontFamily={'"Press Start 2P"'} variant="h3">
              Hi!
            </Typography>
            <Stack
              direction={'row'}
              alignContent={'center'}
              flexWrap={'wrap'}
              gap="20px"
            >
              <Typography fontFamily={'"Press Start 2P"'} variant="h3">
                I'm Nick
              </Typography>
              <Typography alignContent={'center'} variant="h3">
                🌞🪩
              </Typography>
            </Stack>
          </Typography>
          <Stack gap="50px">
            <Typography fontFamily={'"Press Start 2P"'} variant="h5">
              I'm a hopeful technologist focused on building a healthy future
              for tech.
            </Typography>
            <Stack gap={'20px'}>
              <Typography fontFamily={'"Press Start 2P"'} variant="h5">
                I've worked at:
              </Typography>
              <Stack
                direction="row"
                justifyContent={mobile ? 'center' : 'space-between'}
                alignContent={'center'}
                alignItems={'center'}
                flexWrap={'wrap'}
                rowGap={'20px'}
              >
                <img className="logo" src={thred} alt="logo" />
                <img className="logo" src={spotify} alt="logo" />
                <img className="logo" src={microsoft} alt="logo" />
              </Stack>
            </Stack>
            <Typography fontFamily={'"Press Start 2P"'} variant="h5">
              For details about my experience, check out my{' '}
              <a
                style={{ display: 'inline-block' }}
                rel="noreferrer"
                target="_blank"
                href="/Moolenijzer_CV_Industry.pdf"
              >
                CV
              </a>
              .
            </Typography>
          </Stack>
        </Stack>
        {/* <div className="row">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://doi.org/10.1145/3565066.3608705"
          >
            <img className="example-image" src={mobile_hci_poster} alt="logo" />
          </a>
        </div>
        <div className="row">
          <a rel="noreferrer" target="_blank" href="https://thred.works">
            <img className="example-image" src={thred} alt="logo" />
          </a>
        </div>
        <div className="row">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://nmoolenijzer.github.io/final.html"
          >
            <img className="example-image" src={exploring_face} alt="logo" />
          </a>
        </div>
        <div className="row"></div> */}

        {/* <div className="row">
          <div className="icon-link" onClick={() => setClickedItem('fab')}>
            <span className="material-symbols-outlined">view_in_ar</span>
            <div>Fabrication</div>
          </div>
          <div className="icon-link" onClick={() => setClickedItem('writing')}>
            <span className="material-symbols-outlined">history_edu</span>
            <div>Research</div>
          </div>
          <div className="icon-link" onClick={() => setClickedItem('code')}>
            <span className="material-symbols-outlined">terminal</span>
            <div>Software</div>
          </div>
        </div> */}

        {clickedItem === 'fab' ? (
          <div className="row">
            {allItems['fab'].map((item, idx) => {
              return (
                <div className="item" key={`${idx}-${item.caption}`}>
                  <img className="image" src={item.src} alt="logo" />
                  <div className="caption">{item.caption}</div>
                </div>
              );
            })}
          </div>
        ) : clickedItem === 'writing' ? (
          <div className="row">
            {allItems['writing'].map((item, idx) => {
              return (
                <div className="item" key={`${idx}-${item.caption}`}>
                  <img className="image" src={item.src} alt="logo" />
                  <div className="caption">{item.caption}</div>
                </div>
              );
            })}
          </div>
        ) : clickedItem === 'code' ? (
          <div className="row">
            {allItems['code'].map((item, idx) => {
              return (
                <div className="item" key={`${idx}-${item.caption}`}>
                  <img className="image" src={item.src} alt="logo" />
                  <div className="caption">{item.caption}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Application;
